import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { CommonHttpService } from './common-http.service';
import { LocalStorageService } from './local-storage.service';
import constant from './../app.constant';
import { ToastrService } from 'ngx-toastr';
import { NotifyMessagingService } from './notify-messaging.service';
import { NavigationService } from '../shared/services/navigation.service'
import commonLogics from './common-logic.service';
@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    public showLoader: boolean = false;
    public userDetails: any = {};
    notifymessage: any;

    constructor(
        private http: CommonHttpService,
        private router: Router,
        private localStorage: LocalStorageService,
        public toster: ToastrService,
        public navService: NavigationService,
        // public headerService: HeaderService
    ) { }

    tokenSetAndCheckInLocalStorage(token: any) {
        if (token) {
            this.localStorage.addItem(constant.localStorage.token, token);
            this.getUserDetails();
        } else if (!this.localStorage.getItem(constant.localStorage.token)) {
            this.toster.error('Session Expired');
            // location.href = constant.Client.Auth.BaseUrl;
        }
    }
    loginTokenCken() {
        if (this.localStorage.getItem(constant.localStorage.token)) {
            this.router.navigate(['/dashboard']);
            this.getUserDetails();
        } else {
            this.logout();
            // this.router.navigate(['/appcenter/createpwd'], { queryParams: { code: userData.reset_pw_code } })
        }
    }

    getUserDetails() {
        var header: any = {
            'x-access-token': this.localStorage.getItem(constant.localStorage.token),
            'Content-Type': "application/json",
            'utc-offset': commonLogics.getUtcOffset(),
        }
        console.log(header)
        this.http.get(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.User.authGetUser, { headers: header }).subscribe((response: any) => {
            if (response.status) {
                this.userDetails = response.data;
                this.localStorage.addItem(constant.localStorage.user_name, this.userDetails?.full_name);
                this.localStorage.addItem(constant.localStorage.first_name, this.userDetails?.name);
                this.localStorage.addItem(constant.localStorage.role_id, this.userDetails?.role_id);
                this.localStorage.addItem(constant.localStorage.email, this.userDetails?.email);
                this.localStorage.addItem(constant.localStorage.user_id, this.userDetails?.user_id);
            }
            this.sideBarMenu();
        })
    }


    sideBarMenu() {
        var reqJson: any = {};
        var defaultMenu = [];
        reqJson = {
            name: 'Dashboard',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Bar-Chart',
            state: '/dashboard'
        },
            defaultMenu.push(reqJson);
        if (this.localStorage.getItem(constant.localStorage.role_id) == constant.RoleId.master) {
            reqJson = {
                name: 'Orders',
                description: '600+ premium icons',
                type: 'link',
                icon: 'i-Receipt-4',
                state: '/orders/orderlist'
            },
                defaultMenu.push(reqJson);
        }
        if (this.localStorage.getItem(constant.localStorage.role_id) == constant.RoleId.master) {
            reqJson = {
                name: 'Partner',
                description: '600+ premium icons',
                type: 'link',
                icon: 'i-Atom',
                state: '/partner/partner'
            },
                defaultMenu.push(reqJson);
        }
        reqJson = {
            name: 'Business',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Financial',
            state: '/busniess/busniess'
        },
            defaultMenu.push(reqJson);
        reqJson = {
            name: 'Community',
            description: '600+ premium icons',
            type: 'link',
            icon: 'i-Conference',
            state: '/community/community'
        },
            defaultMenu.push(reqJson);
        if (this.localStorage.getItem(constant.localStorage.role_id) == constant.RoleId.master) {
            reqJson = {
                name: 'Coupon',
                description: '600+ premium icons',
                type: 'link',
                icon: 'i-Gift-Box',
                state: '/coupon/couponList'
            },
                defaultMenu.push(reqJson);
        }
        this.navService.menuItems.next(defaultMenu);
    }
    logout() {
        if (this.localStorage.getItem(constant.localStorage.token)) {
            this.http.get(constant.Service.MainMaster.BaseUrl + constant.Service.MainMaster.User.LogOut).subscribe((response: any) => {
                this.toster.success('Logout Success');
                //     console.log(response)
                this.logoutWithoutBackendHit();
            }, err => {
                this.toster.success('Logout Success');
                this.logoutWithoutBackendHit();
            })
        } else {
            this.logoutWithoutBackendHit();
        }
    }

    logoutWithoutBackendHit() {
        this.localStorage.clearAllItem();
        location.href = constant.Client.Auth.BaseUrl;
    }

}
