import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class CommonHttpService {



  constructor(private http: HttpClient) { }

  public postData(url, data, options?) {
    return this.http.post(url, data, options);
  }

  public get(url, options?) {
    return this.http.get(url, options);
  }

  public getDataById(url, id) {
    return this.http.get(url + '/' + id);
  }

  public updateData(url, data, options?) {
    return this.http.put(url, data, options);
  }

  public deleteData(url, id) {
    return this.http.delete(url + '/' + id);
  }

  //common services
  // public getIp() {
  //   return this.http.get('https://jsonip.com')
  // }

}

