import * as _ from 'lodash';
import { environment } from './../../src/environments/environment';
class Constant {
    constructor() {
    }
    minDate = { year: 2018, month: 1, day: 1 };
    maxDate = { year: 2029, month: 12, day: 31 };
    dateFormat = 'DD-MM-YYYY';
    usDateFormat = 'YYYY-MM-DD';
    // dateFormat = 'dd-MM-YYYY'
    DateFormatServerSideDate = "YYYY-MM-DD";
    InternalError = 'Internal Error';
    PageSize = 20;
    // localhost:40211/user/
    localStorage = {
        prefix: 'engaje-',
        token: 'token',
        user_name: 'user_name',
        dp_url: 'dp_url',
        access: 'access',
        user_role_id: 'user_role_id',
        user_id: 'user_id',
        company_details_id: 'company_details_id',
        address: 'address',
        last_name: 'last_name',
        first_name: 'first_name',
        email: 'email',
        phone: 'phone',
        initial_payment: 'initial_payment',
        module_id: 'module_id',
        role_id: 'role_id',
        stripePayment: 'stripePayment'
    };
    DateFormatServerSideWithDateTime = "YYYY-MM-DD HH:mm";
    dateTimeFormat = 'DD-MM-YYYY HH:mm:ss';
    DateFormatWithTimeHtml = 'DD-MM-YYYY HH:mm';

    cryptoJs = {
        algorithm: 'aes-128-cbc',
        secretKey: 'adloggsangular&5&secret@mission'
    }
    RoleId = {
        master: 1,
        partner: 2,
        business: 3,
        staff: 4,
        community: 5
    }

    moduleId = {
        ekart: 12,
        foodCourt: 13,
        cConnect: 2,
        skipques: 1,
        service: 14,
        tableOrdering: 15
    }
    cartStatus = {
        incart: 'In Cart',
        inkitchan: 'In Kitchen',
        cooked: 'Cooked',
        served: 'Served',
        cancelled: 'Cancelled',
    }

    DeliveryType = {
        14: {
            "Click and Collect": "Drop in",
            "Delivery": "Call out service"
        },
        13: {
            "Click and Collect": "Takeaway",
            "Delivery": "Delivery"
        },
        15: {
            "Click and Collect": "Takeaway",
            "Delivery": "Delivery"
        },
        12: {
            "Click and Collect": "Takeaway",
            "Delivery": "Delivery"
        }
    }

    orderStatusName = {
        1: "Pending",
        2: "Received",
        3: "Processing",
        4: "Unserviceable",
        5: "Ready",
        6: "Completed"
    }
    orderStatusId = {
        "Pending": 1,
        "Received": 2,
        "Processing": 3,
        "Unserviceable": 4,
        "Ready": 5,
        "Completed": 6
    }

    Client = {
        Auth: {
            BaseUrl: environment.Service.AuthBaseUrl,
            partnerClientUrl: environment.Service.partnerClientUrl,
            bussinessClientUrl: environment.Service.bussinessClientUrl,
        },
        DeviceLink: {
            link: environment.Service.deviceUrl,
            referralUrl: environment.Service.referralUrl
        }
    }
    // logoUrl = environment.Service.logoUrl;
    // logoIcon = environment.Service.logoIcon;
    ImageUrl = {
        BaseUrl: environment.Service.ImageBaseUrl,
        Engaje: {
            File: {
                Create: "file"
            },
            Create: 'create',
            LoginShowImage: 'https://images.engaje.app/',
            ShowImage: 'image?url=',
            serverImage: 'https://phoenix.ams3.digitaloceanspaces.com/'
        }
    }

    errOrFalseRes = {
        data: [],
        pagination: {
            totalRows: 0,
            rowSize: 10,
            currentPage: 1
        }
    }
    IsoCodeForCountry = {
        "+91": "in",
        "+94": "lk",
        "+44": "uk",
        "+61": "au",
        "+1": "us"
    }
    Service = {
        MainMaster: {
            BaseUrl: environment.Service.EngajeLoginApiBaseUrl,
            BusinessBaseUrl: environment.Service.EngajeBusinessApiBaseUrl,
            Auth: {
                // Logout: 'user/session/logout'
            },
            Event: {
                Create: 'communityevent/create',
                GetAll: 'communityevent/getAll',
                Update: 'communityevent/update',
            },
            Group: {
                create: 'communitygroup/create',
                GetAll: 'communitygroup/getAll',
                Update: 'communitygroup/update',
            },
            BookedEvent: {
                GetAll: 'communityeventbooking/getAll',
                Update: 'communityeventbooking/update',
                Resend: 'communityeventbooking/resend/bookingemail'
            },

            User: {
                Login: 'user/login',
                LogOut: 'user/logout',
                GetUser: 'user/getusers',
                authGetUser: 'user/getuser',
                Create: 'user/partnercommcreate',
                // BusniessCreate: 'user/partnercommcreate',
                // CommunityCreate: 'user/partnercommcreate',
                CommunityType: 'community/memcommlist',
                CheckUserName: 'user/checkusername',
                Createpwd: 'user/createpwd',
                // Companydtlcreate: 'cmpdtl/create',
                // Companydtlupdate: 'cmpdtl/update',
                resetEmailCode: 'user/pwresetcode',
                resetPassword: 'user/resetpw',
                Userupdate: 'user/update',
                Usercreate: 'user/create',
                socialLinkCreate: 'usersocialdetail/create',
                socialLinkDelete: 'usersocialdetail/delete',
                // Companydtlget: 'cmpdtl/get'
                PartnetGenerateTok: 'user/getaccesstoken'
            },
            CommunityMessage: {
                CustomerMessage: 'cc/broadcast/notification'
            },
            DashBoard: {
                GetCount: 'community/getcount',
            },
            Coupon: {
                GetAll: 'coupon/get',
                Create: 'coupon/create',
                Update: 'coupon/updatecoupon',
            },
            Customer: {
                GetAll: 'customer/getallcus',
            },
            Payment: {
                CalculatePrice: 'payment/pricecalc',
                Bussinesspayment: 'payment/module',
                Paymenturl: 'store/oauth/stripe/acc',
                StoreIntegration: 'store/stripeaccountinteg',
                GetAll: 'payment/txn'
            },
            device: {
                UpdateDevice: 'favorjoinfromlink/create',
                getIp: 'dynamic/getip',
                deviceCheck: 'favorjoinfromlink/app/favstoreandcomm'
            },

            Dynamic: {
                Dynamicapi: 'dynamic',
            },
            module: {
                // userModules: 'module/getallsubmodule'
            },
            Location: {
                LocationGetall: 'location/getall',
            },
            Order: {
                GetAll: 'order/getall',
                GetById: 'order/getbyid',
                ChangeQuantity: 'order/chgquantity',
                CancelCart: 'order/cancelcart',
                OrderUpdate: 'order/update',
                OrderCount: 'order/ordercount',
                OrderUpdateWithOutToken: 'order/updatebyuuid',
                DpCancel: 'order/canceltodp'
            },


        }

    }
}


let constant = new Constant();
export default constant;